export default {
  type: "events",
  title: null,
  color: null,
  excerpt: "",
  relationshipNames: [
    "organization",
    "allowedLocations",
    "serviceCenters",
    "establishments",
    "cafeterias",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  allowedLocations: [],
  serviceCenters:[],
  establishments: [],
  cafeterias: []
};
