export const EVENT_CATEGORY_EVENT = 'EVENT';
export const EVENT_CATEGORY_LEAVE = 'LEAVE';
export const EVENT_CATEGORY_SPECIAL_THEME_MENU = 'SPECIAL_THEME_MENU';
export const EVENT_CATEGORY_TRAINING_MEETING = 'TRAINING_MEETING';
export const EVENT_CATEGORY_DEADLINE_DELIVERABLE = 'DEADLINE_DELIVERABLE';
export const EVENT_CATEGORY_OTHER = 'OTHER';

export const EVENT_CATEGORIES = [
  EVENT_CATEGORY_EVENT,
  EVENT_CATEGORY_LEAVE,
  EVENT_CATEGORY_SPECIAL_THEME_MENU,
  EVENT_CATEGORY_TRAINING_MEETING,
  EVENT_CATEGORY_DEADLINE_DELIVERABLE,
  EVENT_CATEGORY_OTHER
];

export const EVENT_CATEGORIES_COLORS = {
  [EVENT_CATEGORY_EVENT]: '#C0392B',             
  [EVENT_CATEGORY_LEAVE]: '#27AE60',             
  [EVENT_CATEGORY_SPECIAL_THEME_MENU]: '#2980B9',  
  [EVENT_CATEGORY_TRAINING_MEETING]: '#F39C12',   
  [EVENT_CATEGORY_DEADLINE_DELIVERABLE]: '#8E44AD', 
  [EVENT_CATEGORY_OTHER]: '#2C3E50'              
};